.about .about-header {
	background-image: url('./../../assets/MaskedHeroImag2.png');
	background-position: center center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	display: flex;
	height: 500px;
	align-items: center;
	padding-left: 130px;
}

.about .about-header img {
	width: 200px;
}

.about .tabs-conatiner {
	min-height: 500px;
	padding: 50px 130px;
}

.about .tabs-conatiner P {
	color: #03151f;
	font-size: 18px;
}

.about .tabs-conatiner h4 {
	color: #204269;
}

.about .tabs-conatiner h2 {
	margin-bottom: 8px;
	margin-top: -7px;
	font-weight: bold;
	color: #204269;
}

.about .tabs-conatiner .react-tabs {
	display: flex;
}

.about .tabs-conatiner .react-tabs .react-tabs__tab-list {
	flex-direction: column;
	display: flex;
	padding-right: 20px;
	border-bottom: none;
	margin-right: 40px;
}

.about .tabs-conatiner .react-tabs .react-tabs__tab {
	text-align: center;
	margin-bottom: 20px;
}

.about .tabs-conatiner .react-tabs .react-tabs__tab--selected {
	border: none;
	border-radius: 5px;
	background-color: #204269;
	color: #fff;
	width: 150px;
	text-align: center;
}

.about .tabs-conatiner .react-tabs .our-team ul {
	padding-left: 20px;
}

.about .tabs-conatiner .react-tabs .our-team ul li {
	padding-left: 0px;
}

.about .tabs-conatiner .react-tabs .our-team h4 {
	margin-bottom: 20px;
}

.about .tabs-conatiner .value-cards {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 50px;
}

.about .tabs-conatiner .value-cards .icon-card-container {
	box-shadow: 0px 6px 9px #c4c4c4;
	border: 1px solid #c4c4c4;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 200px;
	text-align: center;
}

.about .tabs-conatiner h4 {
	color: #204269;
	font-weight: bold;
}

@media screen and (max-width: 768px) {
	.about .tabs-conatiner {
		padding: 50px 24px;
	}

	.about .tabs-conatiner .react-tabs {
		display: block;
	}

	.about .tabs-conatiner .react-tabs .react-tabs__tab-list {
		flex-direction: row;
		font-family: Ubuntu-Medium;
	}

	.about .about-header {
		height: unset;
		align-items: unset;
		justify-content: center;
		padding: 50px 0;
	}

	.about .tabs-conatiner .react-tabs .react-tabs__tab {
		text-transform: lowercase;
		width: 115px;
	}

	.about .tabs-conatiner h2,
	.about .tabs-conatiner h4 {
		font-size: 24px;
	}

    .about .tabs-conatiner P,
    .about .tabs-conatiner .react-tabs .our-team ul li {
		font-family: Ubuntu-Regular;
		margin: 4px 0 !important;
	}

	.about .tabs-conatiner h2,
	.about .tabs-conatiner h4 {
		font-weight: bold;
		color: #204269;
		font-size: 24px;
    }
    
    .about .tabs-conatiner .react-tabs .our-team ul li {
        margin: 8px 0;
    }

    .about .tabs-conatiner .value-cards {
	    display: grid;
    	grid-template-columns: repeat(2, 1fr);
	}
	
	.about .tabs-conatiner .value-cards .icon-card-container {
		width: 100px;
		height: 100px;
		margin: auto;
	}

	.about .tabs-conatiner P {
		font-family: Ubuntu-Medium;
	}

    .about .tabs-conatiner .value-cards .icon-card-container {
        margin-bottom: 35px;
	}
	
	.about .tabs-conatiner .react-tabs .react-tabs__tab-list {
		padding-right: 0;
		margin-right: 0;
	}

	.about .tabs-conatiner P {
		font-size: 12px;
		margin-bottom: 0;
		margin-top: 8px;
	}
	.about .tabs-conatiner h2, .about .tabs-conatiner h4 {
		margin-bottom: 8px;
	}
	
}
