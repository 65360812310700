.services .services-header {
	background-image: url('./../../assets/homeHeader.jpg');
	background-position: center center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	display: flex;
	height: 600px;
	align-items: center;
	padding-left: 130px;
}

.services .services-header h2 {
	color: #fff;
	font-size: 58px;
	font-weight: 300;
}

.services .services-header h2 span {
	font-weight: 500;
}

.services .swiper {
	padding: 50px 0;
	background-image: url('./../../assets/MaskedHeroImag1.jpg');
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
}

.swiper-wrapper {
	align-items: center;
}

.services .swiper-slide {
	text-align: center;
	font-size: 18px;
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	flex-direction: column;
	padding: 0 165px;
}

.services .swiper-slide h1 {
	color: #fff;
	margin-bottom: 20px;
}

.services .swiper-slide ul li {
	color: #fff;
	text-align: left;
}

.services .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-pagination-bullet {
	width: 15px;
	height: 15px;
}

.swiper-pagination-bullet-active {
	background-color: #fff !important;
}

.services .swiper-slide ul li {
	font-family: Ubuntu-Regular;
}

@media screen and (max-width: 768px) {
	.services .swiper {
		height: 831px;
		margin-top: 32px;
		margin-bottom: 16px;
	}

	.services .swiper-slide ul li {
		font-family: Ubuntu-Regular;
		font-size: 14px;
	}

	.swiper-horizontal > .swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal {
		bottom: 18px;
	}
	.services .swiper-slide {
		padding: 0 24px;
	}
	.swiper-button-prev, .swiper-rtl .swiper-button-next,
	.swiper-button-next, .swiper-button-prev { 
		display: none;
	}
}
