.navbar {
    background-color: #fff !important;
}

.nav-container {
    padding: 0 110px;
}

@media screen and (max-width: 991px) {
    .nav-container .navbar-collapse {
        text-align: center;
    }

    .nav-container .navbar-nav-scroll {
        min-height: 100px;
        max-height: unset !important;
    }
}

@media screen and (min-width:991px) {
    .mobile-side-menu {
        display: none !important;
    }
}

.navbar .nav-container .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar .nav-container .menu-item p {
    margin-bottom: 0;
    color: #000;
    margin-left: 10px;
    font-weight: bold;
}

.nav-container .logo-container {
    margin-right: 20px;
    margin-left: 20px;
}

.nav-container .navbar-links {
    color: #03151F !important;
    text-decoration: none !important;
    padding: 8px;
    font-size: 17px;
    font-weight: 600;
}

#navbarScrollingDropdown {
    color: #03151F !important;
    font-weight: 600;
    font-size: 17px;
}

.nav-container .navbar-links:hover {
    color: #03151F;
}


.lang-btn {
    border: 1px solid #D2D4DB !important;
    background-color: #fff !important;
    color: #03151F !important;
    font-weight: 600;
}

.dropdown-menu {
    left: 60px !important;
    top: 50px !important;
    background-color: #EAE9F5 !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.slice-top {
    position: absolute;
    top: -22px;
}

.to-projects {
    padding: 0 !important;
    text-decoration: none;
}