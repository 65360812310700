.footer {
	background-image: url("./../../assets/footer.jpg");
	background-position: center center;
	background-size: 100% 100%;
	padding: 50px 130px;
	background-repeat: no-repeat;
}

.footer.bnpl {
	background-image: url("./../../assets/BnplIcons/BG.jpg");
}

.footer.qr {
	background-image: url("./../../assets/qrFooter.jpg");
}

.footer.finpay {
	background-image: url("./../../assets/finpayIcons/finbayBG.jpg");
}

.footer .subscribe {
	padding: 0 100px;
}

.footer h2 {
	margin-bottom: 20px;
	color: #fff;
}

.footer h6,
p {
	color: #fff;
}

.footer input {
	border: none;
	border-bottom: 1px solid #fff;
	width: 100%;
	margin-bottom: 20px;
	background-color: transparent;
	color: #fff;
}

.footer input::placeholder {
	color: #fff;
}

.footer .button {
	display: flex;
	justify-content: flex-end;
}

.footer .sub {
	background-color: #fff;
	color: #000;
	border: none;
	width: 150px;
	font-weight: bold;
}

.footer .footer-btn {
	display: none;
}

.footer .btn-content {
	display: none;
}

@media screen and (max-width: 768px) {
	.footer {
		padding: 50px 36px;
		font-family: Ubuntu-Medium;
	}

	.footer .row .col:first-child {
		padding-left: 0;
		margin-bottom: 34px;
	}

	.footer .subscribe {
		padding: 0;
		display: contents;
	}

	.footer .sub,
	.footer .button {
		width: 100%;
	}

	.footer .title h2 {
		font-size: 24px;
	}

	.footer .title h2 {
		font-size: 20px;
		margin-left: -13px;
		position: relative;
		left: 12px;
	}

	.footer h2 {
		font-size: 18px;
	}

	.footer h6,
	p {
		color: #fff;
		font-size: 15px;
	}
}

@media only screen and (min-width: 820px) {
	.footer {
		padding: 50px 48px;
	}
}

@media only screen and (max-width: 767px) {
	.footer .title h2 {
		text-align: center;
		font-weight: 600;
		font-size: 48px;
	}

	.footer .footer-content {
		display: none;
	}

	.footer .footer-btn {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 40px;
	}

	.footer .footer-btn .btn {
		background-color: transparent;
		border-color: #fff;
	}

	.footer .btn-content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		margin-top: 40px;
		background-color: rgba(255, 255, 255, 0.2);
		padding: 30px 0 20px;

	}
}