.qr-project .qr-header {
	background-image: url('./../../assets/qr-header.jpg');
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
	padding-left: 130px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.qr-project .qr-header h2 {
	color: #fff;
	font-size: 58px;
	font-weight: 300;
	margin-bottom: 40px;
}

.qr-project .qr-header p {
	color: #fff;
	margin-bottom: 40px;
}

.qr-project .qr-header .btn {
	background-color: #fff;
	width: 150px;
	color: #1f1e1d;
	border: none;
	font-weight: 600;
}

.qr-project .how-work {
	padding: 50px 130px;
}

.qr-project .how-work h2 {
	font-weight: bold;
}

.qr-project .how-work .work-item-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 70px;
}

.qr-project .how-work .work-item-container .work-item {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.qr-project .how-work .work-item-container .work-item p {
	color: #ff5a00;
	border: 2px solid #212122;
	text-align: center;
	width: 50px;
	height: 50px;
	border-radius: 100% 100%;
	padding-top: 7px;
	font-size: 22px;
	font-weight: bold;
}

.qr-project .how-work .work-item-container .work-item h5 {
	font-size: 24px;
	margin: 0 0 10px;
	font-weight: bold;
}

.qr-project .how-work .work-item-container .work-item h6 {
	text-align: center;
	font-size: 14px;
	line-height: 18px;
}

.qr-project .for-whom {
	background-image: url('./../../assets/whom.png');
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}

.qr-project .for-whom {
	padding: 50px 130px;
}

.qr-project .for-whom h2 {
	text-align: center;
	font-weight: bold;
	margin-bottom: 20px;
	color: #fff;
}

.qr-project .for-whom h6 {
	text-align: center;
	margin-bottom: 40px;
	color: #fff;
	font-weight: 400;
}

.qr-project .whom-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.qr-project .payment-container {
	padding: 50px 130px;
}

.qr-project .payment-container h2 {
	color: #1f1e1d;
	font-weight: bold;
	margin-bottom: 20px;
	margin-top: 20px;
}

.qr-project .payment-container p {
	color: #1f1e1d;
	font-weight: 400;
}

.qr-project .payment-container .cards-container {
	display: flex;
	justify-content: space-between;
}

.qr-project .payment-container .cards-container img {
	width: 180px;
}

.qr-project .why-qr h2 {
	text-align: center;
}

.qr-project .why-qr p {
	text-align: center;
	color: #1f1e1d;
	font-size: 14px;
	margin-top: 40px;
}

.qr-project .why-qr .work-item-container .work-item img {
	margin-bottom: 20px;
	width: 64px;
	height: 64px;
}

.qr-project .payment-container .scan-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.qr-project .payment-container .scan-container h2 {
	font-size: 40px;
}

.qr-project .payment-container .scan-container p {
	margin-bottom: 30px;
	font-size: 18px;
}

.qr-project .payment-container .scan-container img {
	width: 250px;
	margin-bottom: 40px;
}

.bank-cards-mobile,
.mobile-finpay {
	display: none !important;
}

.web-finpay {
    display: block !important;
}

@media screen and (max-width: 768px) {
	.qr-project .qr-header {
		height: unset;
		padding: 60px 0;
	}
	.qr-project .qr-header {
		padding-left: 0;
		text-align: center;
	}
	.qr-project .qr-header h2 {
		font-size: 32px;
		font-family: 'Ubuntu-Regular';
		margin-bottom: 13px;
	}
	.qr-project .qr-header h2 span {
		font-family: 'Ubuntu-Bold';
	}
	.qr-project .qr-header p {
		font-family: 'Ubuntu-Regular';
		width: 98%;
		margin: 0 auto;
	}
	.qr-project .qr-header p br {
		display: none;
	}
	.contact-btn {
		text-align: center;
		margin-top: 40px;
	}
	.qr-project .qr-header .btn {
		padding: 11px;
		font-family: 'Ubuntu-Bold';
	}
	.qr-project .how-work {
		padding: 0;
	}
	.qr-project .how-work .work-item-container {
		display: block;
		margin-top: 37px;
	}
	.qr-project .how-work h2 {
		font-family: 'Ubuntu-Bold';
		font-size: 32px;
		text-align: center;
		margin-top: 26px;
	}
	.qr-project .how-work .work-item-container .work-item p {
		width: 80px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 4px;
	}
	.qr-project .how-work .work-item-container .work-item h5 {
		font-family: 'Ubuntu-Medium';
	}
	.qr-project .how-work .work-item-container .work-item h6 {
		font-family: 'Ubuntu-Regular';
	}
	.qr-project .how-work .work-item-container .work-item {
		margin-bottom: 40px;
	}
	.qr-project .for-whom {
		height: unset;
		padding: unset;
	}
	.qr-project .for-whom h2 {
		font-size: 32px;
		font-family: 'Ubuntu-Medium';
		padding-top: 26px;
	}
	.qr-project .for-whom h6 {
		font-size: 14px;
		margin-top: 4px;
		font-family: 'Ubuntu-Regular';
		width: 80%;
		margin: auto;
		margin-bottom: 26px;
	}
	.qr-project .for-whom h6 br {
		display: none;
	}
	.qr-project .whom-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	.qr-project .payment-container {
		padding: 0;
	}
	.qr-project .payment-container h2,
	.qr-project .payment-container p {
		margin: 0;
	}
	.qr-project .payment-container h2 {
		font-size: 26px;
		font-family: 'Ubuntu-Medium';
		text-align: center;
		margin-top: 55px;
		margin-bottom: 11px;
	}
	.qr-project .payment-container p {
		text-align: center;
		font-family: 'Ubuntu-Regular';
		width: 83%;
		margin: auto;
	}
	.qr-project .payment-container p br {
		display: none;
	}
	.qr-project .payment-container .cards-container {
		display: none;
	}
	.bank-cards-mobile {
		display: flex !important;
		justify-content: space-between;
		margin: 24px 0;
		padding: 0 25px;
	}
	.qr-project .why-qr p br {
		display: none;
	}
	.qr-project .why-qr p {
		width: 95%;
		margin: 15px auto 0 auto;
	}
	.qr-project .payment-container .scan-container {
		display: none;
	}
	.mobile-finpay {
		display: block !important;
    }
    .web-finpay {
        display: none !important;
	}
	.qr-style {
		margin-left: 12px;
		color: #1F1E1D;
		text-decoration: none;
		font-weight: bold;
		font-family: 'Ubuntu-Bold';
		display: inline-block;
	}
	.row {
		--bs-gutter-x: 0;
		display: block;
	}
}
