@font-face {
	font-family: Roboto-Bold;
	src: url('./assets/fonts/Roboto-Bold.ttf');
}

@font-face {
	font-family: Roboto-Light;
	src: url('./assets/fonts/Roboto-Light.ttf');
}

@font-face {
	font-family: Roboto-Medium;
	src: url('./assets/fonts/Roboto-Medium.ttf');
}

@font-face {
	font-family: Roboto-Regular;
	src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
	font-family: Ubuntu-Medium;
	src: url('./assets/fonts/Ubuntu-Medium.ttf');
}

@font-face {
	font-family: Ubuntu-Regular;
	src: url('./assets/fonts/Ubuntu-Regular.ttf');
}

@font-face {
	font-family: Ubuntu-Bold;
	src: url('./assets/fonts/Ubuntu-Bold.ttf');
}

._roboto-bold {
	font-family: Roboto-Bold !important;
}

._roboto-light {
	font-family: Roboto-Light;
}

._roboto-medium {
	font-family: Roboto-Medium;
}

._roboto-regular {
	font-family: Roboto-Regular;
}

._Ubuntu-Medium {
	font-family: Ubuntu-Medium;
}

._Ubuntu-Regular {
	font-family: Ubuntu-Regular;
}

._Ubuntu-Bold {
	font-family: Ubuntu-Bold;
}
