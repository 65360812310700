.icon-card-container {
    background-color: #fff;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    width: 150px;
    align-items: center;
    border-radius: 25px;
}

.icon-card-container img {
    width: 50px;
    height: 50px;
}

.icon-card-container p {
    color: #1F1E1E;
    margin-top: 10px;
    font-size: 18px;
    margin-bottom: 0;
}