.finpay-project .finpay-header {
	background-image: url('./../../assets/finpayIcons/headerBG.jpg');
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
	padding-left: 130px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.finpay-project .finpay-header h2 {
	color: #fff;
	font-size: 58px;
	font-weight: 300;
	margin-bottom: 40px;
}

.finpay-project .finpay-header p {
	color: #fff;
	margin-bottom: 40px;
}

.finpay-project .finpay-header .btn {
	background-color: #fff;
	width: 150px;
	color: #1f1e1d;
	border: none;
	font-weight: 600;
}

.finpay-project .how-work {
	padding: 50px 130px;
}

.finpay-project .how-work h2 {
	font-weight: bold;
}

.finpay-project .how-work .work-item-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 70px;
}

.finpay-project .how-work .work-item-container .work-item {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.finpay-project .how-work .work-item-container .work-item p {
	color: #ff5a00;
	border: 2px solid #212122;
	text-align: center;
	width: 50px;
	height: 50px;
	border-radius: 100% 100%;
	padding-top: 7px;
	font-size: 22px;
	font-weight: bold;
}

.finpay-project .how-work .work-item-container .work-item h5 {
	font-size: 24px;
	margin: 0 0 10px;
	font-weight: bold;
}

.finpay-project .how-work .work-item-container .work-item h6 {
	text-align: center;
	font-size: 14px;
	line-height: 18px;
}

.finpay-project .solution-container {
	padding: 50px 130px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.finpay-project .solution-container h2 {
	font-weight: bold;
	margin-bottom: 20px;
}

.finpay-project .solution-container p {
	color: #1f1e1d;
}

.finpay-project .solution-container img {
	margin-bottom: -50px;
}

.finpay-project .for-whom {
	background-image: url('./../../assets/finpayIcons/easyFinpay.jpg');
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}

.finpay-project .for-whom {
	padding: 50px 130px;
}

.finpay-project .for-whom h2 {
	text-align: center;
	font-weight: bold;
	margin-bottom: 20px;
	color: #fff;
}

.finpay-project .for-whom h6 {
	text-align: center;
	margin-bottom: 40px;
	color: #fff;
	font-weight: 400;
}

.finpay-project .whom-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.finpay-project .payment-container {
	padding: 50px 90px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.finpay-project .payment-container h2 {
	color: #1f1e1d;
	font-weight: bold;
	margin-bottom: 40px;
	margin-top: 20px;
}

.finpay-project .payment-container p {
	color: #1f1e1d;
	font-weight: 400;
}

.finpay-project .payment-container .cards-container .bank-cards {
	display: flex;
}

.finpay-project .payment-container .cards-container img {
	width: 180px;
}

.finpay-project .why-qr h2 {
	text-align: center;
}

.finpay-project .why-qr p {
	text-align: center;
	color: #1f1e1d;
	font-size: 14px;
	margin-top: 40px;
}

.finpay-project .why-qr .work-item-container .work-item img {
	margin-bottom: 20px;
	width: 64px;
	height: 64px;
}

.finpay-project .payment-container .scan-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.finpay-project .payment-container .scan-container h2 {
	font-size: 40px;
}

.finpay-project .payment-container .scan-container p {
	margin-bottom: 30px;
	font-size: 18px;
}

.finpay-project .payment-container .scan-container img {
	width: 250px;
	margin-bottom: 40px;
}

.bank-cards-mobile,
.finpay-register-img,
.mobile-finpay,
.finpay-mob
 {
    display: none;
}

@media screen and (max-width: 768px) {
	.finpay-project .finpay-header {
        padding: 55px 40px;
        height: unset;
    }
    .finpay-project .finpay-header h2 {
        text-align: center;
        margin: 0 0 14px 0;
    }

    .finpay-project .finpay-header h2 span {
        font-family: Ubuntu-Bold;
        font-size: 32px;
        display: block;
    }

    .finpay-project .finpay-header h2 span:last-child {
        font-family: Ubuntu-Regular;
        font-size: 32px;
    }
    .finpay-project .finpay-header p {
        text-align: center;
        font-family: 'Ubuntu-Medium';
        margin: 0 0 26px 0;
    }
    .finpay-project .finpay-header .btn {
        font-family: 'Ubuntu-Medium';
    }
    .finpay-project .solution-container {
        margin-top: 25px;
        display: block;
        padding: 0;
    }
    .finpay-project .solution-container h2 {
        font-family: 'Ubuntu-Medium';
        text-align: center;
    }
    .finpay-project .solution-container p {
        text-align: center;
        font-family: 'Ubuntu-Regular';
        width: 80%;
        margin: auto;
        margin-bottom: 56px;
    }
    .finpay-project .solution-container img {
        margin-bottom: 0;
    }
    .finpay-project .for-whom {
        padding: 47px 49px;
        margin-top: 24px;
    }
    .finpay-project .whom-container {
        display: block;
    }
    .finpay-project .for-whom h2 {
        font-size: 32px;
        font-family: 'Ubuntu-Medium';
        margin-bottom: 17px;
    }
    .finpay-project .for-whom h6 {
        font-family: 'Ubuntu-Medium';
        font-size: 14px;
        margin-bottom: 52px;
    }
    .icon-card-container {
        width: 100px;
        height: 100px;
        margin: auto;
        padding:30px 7px;
        border-radius: 16px;
    }
    .icon-card-container img {
        width: 21px;
        height: 21px;
    }
    .icon-card-container p {
        font-size: 14px;
        margin-bottom: 0;
        font-family: 'Ubuntu-Medium';
    }
    .finpay-project .whom-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .icon-card-container {
        margin-bottom: 32px;
    }
    .finpay-project .for-whom {
        height: initial;
    }
    .finpay-project .payment-container {
        padding: 0;
        margin-bottom: 42px;
    }
    .finpay-project .payment-container h2 {
        font-family: 'Ubuntu-Medium';
        font-size: 24px;
        text-align: center;
    }
    .finpay-project .payment-container .cards-container img {
        width: 93px;
        height: 93px;
        margin: auto;
    }
    .payment-container .container .row .col:last-child img {
        display: none;
    }

    .payment-container .container .row .col  {
        flex: unset;
    }

    .finpay-project .payment-container .cards-container .bank-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .finpay-project .how-work {
        padding: 0;
        margin-top: 25px;
    }
    .finpay-project .why-qr h2 {
        font-family: 'Ubuntu-Bold';
        font-size: 32px;
        margin-bottom: 12px;
    }
    .finpay-project .why-qr p {
        width: 80%;
        margin: auto;
        font-family: 'Ubuntu-Medium';
    }
    .finpay-project .how-work .work-item-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 32px;
        padding-left: 13px;
    }
    .finpay-project .why-qr .work-item-container .work-item img {
        width: 35px;
        height: 35px;
        margin-bottom: 8px;
    }
    .finpay-project .how-work .work-item-container .work-item h5 {
        font-size: 16px;
        font-family: 'Ubuntu-Bold';
    }
    .finpay-project .how-work .work-item-container .work-item h6 {
        font-size: 13px;
    }
    .finpay-project .how-work .work-item-container .work-item {
        margin-bottom: 32px;
    }
    .finpay-project .how-work .work-item-container .work-item h6 br {
        display: none;
    }
    .finpay-project .payment-container .cards-container .bank-cards {
        display: none;
    }
    .bank-cards-mobile {
        display: flex !important;
        justify-content: flex-start;
        gap: 30px;
    }
    .finpay-register-img {
        display: block !important;
        position: relative;
        bottom: -66px;
        z-index: 10002;
        right: -75px;
    }
    .form-card h2 {
        margin-top: 100px;
    }
    .featuers-container {
        display: block;
        padding: 0;
        height: initial;
        position: relative;
    }
    .featuers-container img {
        position: absolute;
        width: 128px;
        height: 260px;
        right: 17px;
        top: 133px;
    }
    .featuers-container .featuers-left{
        text-align: left;
        padding-top: 60px;
        padding-left: 20px;
        width: 62%;
    }
    .featuers-container .featuers-right {
        text-align: left;
        padding-left: 20px;
        padding-bottom: 60px;
        width: 60%;
    }
    .featuers-container p {
        margin: 0;
    }
    .featuers-container .featuers-left h3,
    .featuers-container .featuers-right h3 {
        margin-bottom: 4px;
        margin-top: 12px;
        font-family: 'Ubuntu-Bold';
        font-size: 16px;
    }
    .featuers-container .featuers-left p,
    .featuers-container .featuers-right p {
        font-family: 'Ubuntu-Regular';
        font-size: 14px;
     }
     .web-finpay {
         display: none;
     }
     .mobile-finpay {
        display: block;
    }
    .form-card {
        position: relative;
    }
    .finpay-download-btn,
    .finpay-mob {
        text-align: center;
    }
    .finpay-project .solution-container img {
        display: block;
        margin: auto;
    }

    .finpay-web {
        display: none;
    }
}
