@media only screen and (max-width: 912px) {
  .web-menu {
    display: none;
  }
}

@media only screen and (max-width: 820px) {
  .web-menu,
  #proj-nav {
    display: none;
  }
  .mobile-side-menu a {
    color: #000;
    text-decoration: none;
  }
  .mobile-side-menu a img {
    margin-right: 12px;
  }
}

@media only screen and (max-width: 375px) {
  .mobile-side-menu .menu-items .menu {
    left: -64px;
    height: 104%;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-side-menu .menu-items .menu {
    left: -72px !important;
    height: 104% !important;
  }
}

@media only screen and (min-width: 760px) {
  .mobile-side-menu .menu-items .menu {
    left: -61px !important;
    height: 104% !important;
  }
}

@media only screen and (max-width: 280px) {
  .services .swiper {
    height: 1160px !important;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 3px !important;
  }
}
