.home-header {
	background-image: url('./../../assets/homeHeader.jpg');
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 600px;
	display: flex;
	align-items: center;
	padding-left: 130px;
}

.home-header h2 {
	color: #fff;
	font-size: 58px;
	font-weight: 300;
}

.home-header h2 span {
	font-weight: 500;
}

@media screen and (max-width: 768px) {
	.home-header {
		padding-left: 0 !important;
		justify-content: center;
	}
	.home-header {
		background-image: url('./../../assets/homeHeader.jpg');
		background-position: center;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		height: 361px;
		display: flex;
		align-items: center;
		padding-left: 130px;
	}

	.home-header h2 {
		color: #fff;
		font-size: 40px;
		font-weight: 300;
		text-align: center;
		font-family: Ubuntu-Medium;
	}
}
