.form-card-container {
    background-color: #204269;
    margin-top: 48px;
    margin-bottom: 48px;
    padding: 4px 20px 15px;
    border-radius: 25px;
}

.form-card .qr {
    background-color: #61422E;
}

.form-card .bnpl {
    background-color: #35164F;
}

.form-card .finpay {
    background-color: #D04E06;
}

.form-card h2 {
    color: #fff;
    margin-bottom: 20px;
}

.form-card .get-col input {
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    width: 100%;
    margin-bottom: 30px;
    background-color: transparent;
}

.form-card .get-col .PhoneInput {
    margin-bottom: 30px;
}

.form-card .get-col .PhoneInputInput {
    margin-bottom: 0;
}

.form-card .PhoneInputCountry {
    color: #fff;
}

.form-card .button {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
}

.form-card .button .btn {
    background-color: #fff;
    color: #000;
    border: none;
    width: 150px;
    font-weight: bold;
}

.form-card .have-image {
    display: flex;

    justify-content: center;
}

.form-card .get-col {
    padding: 43px 70px;
}

.form-card .get-col input::placeholder {
    color: #fff;
}

.form-card .hasType {
    color: #fff !important;
}

@media screen and (max-width: 768px) {
    .form-card-container {
        border-radius: 0;
        width: 100%;
        max-width: 100%;
        margin-top: 7px;
        margin-bottom: 38px;
        position: relative;
        top: -4px;
    }

    .form-card .have-image {
        display: none;
    }

    .form-card .button {
        justify-content: center;
        margin-bottom: 20px;
        height: 50px;
    }

    .form-card h2, .form-card p {
        text-align: center;
    }

    .form-card h2 {
        font-family: Ubuntu-Medium;
        font-size: 40px;
    }

    .form-card p {
        font-family: Roboto-Medium;
        font-size: 14px;
        width: 75%;
        margin: auto;
    }

    .form-card .get-col input {
        font-family: Roboto-Medium;
        font-size: 14px;
    }

    .form-card .button .btn {
        width: 180px;
        height: 56px;
        font-weight: bold;
        border-radius: 6px;
    }
    .form-card .get-col {
        padding: 0 16px;
    }
}