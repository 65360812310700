.home .each-container {
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 500px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 0.6) 100%), url('./../../assets/unsplash.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 130px;
}

.home .each-container h2 {
    font-weight: bold;
    margin-bottom: 25px;
}

.home .each-container p {
    color: #000;
}

.home .each-container p span {
    font-weight: 600;
}

@media screen and (max-width: 768px) { 
    .home .each-container {
        background-repeat: no-repeat;
        background:linear-gradient(rgba(26, 46, 53, 0.83),rgba(26, 46, 53, 0.83)), url('./../../assets/unsplash.jpg');
        background-size: cover;
        margin-top: 32px;
        text-align: center;
        padding: 60px 0;
    }
    
    .home .each-container h2 {
        font-weight: bold;
        margin-bottom: 25px;
        font-size: 24px;
        color: #fff;
        font-family: Ubuntu-Medium;
    }
    
    .home .each-container p {
        color: #fff;
        font-size: 14px;
        width:87%;
        margin: 12px auto;
        margin-top: 0;
    } 

    .home .each-container p > p {
        width: 100%;
    }

    .home .each-container p span {
        font-weight: 800;
        font-family: Roboto-Medium;
    }

    .home .each-container p:last-child br {
        display: none;
    }
}