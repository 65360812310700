.bnpl-project .bnpl-header {
    background-image: url("./../../assets/BnplIcons/bnplBack.jpg");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 500px;
    padding-left: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bnpl-project .bnpl-header h2 {
    color: #fff;
    font-size: 58px;
    font-weight: bold;
    margin-bottom: 40px;
}

.bnpl-project .bnpl-header h2 span {
    font-size: 40px;
    font-weight: 500;
}

.bnpl-project .bnpl-header p {
    color: #fff;
    margin-bottom: 40px;
}

.bnpl-project .bnpl-header .btn {
    background-color: #fff;
    width: 150px;
    color: #1F1E1D;
    border: none;
    font-weight: 600;
}

.bnpl-project .how-work {
    padding: 50px 130px;
}

.bnpl-project .how-work .work-item-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 70px;
}

.bnpl-project .how-work .work-item-container .work-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bnpl-project .how-work .work-item-container .work-item p {
    color: #FF5A00;
    border: 2px solid #212122;
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 100% 100%;
    padding-top: 7px;
    font-size: 22px;
    font-weight: bold;
}

.bnpl-project .how-work .work-item-container .work-item h5 {
    font-size: 24px;
    margin: 0 0 10px;
    font-weight: bold;
}

.bnpl-project .how-work .work-item-container .work-item h6 {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
}

.bnpl-project .how-work .part {
    margin-top: 20px;
}

.bnpl-project .how-work .part img {
    margin: 20px
}


.bnpl-project .where-container h2 {
    font-weight: bold;
    margin-bottom: 30px;
}

.bnpl-project .where-container p {
    color: #03151F;
}

.bnpl-project .where-container .know-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.bnpl-project .where-container .know-container .know-card {
    border: 1px solid #C4C4C4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 6px 9px #c4c4c4;
    width: 200px;
    transition: color .5s ease-in-out;
    cursor: pointer;
}

.bnpl-project .where-container .know-container .know-card:hover {
    border: none;
    background-color: #4F0C84;
    transition: color .5s ease-in-out;
}


.bnpl-project .where-container .know-container .know-card img {
    margin-bottom: 15px;
    width: 64px;
    height: 64px;
}

.bnpl-project .where-container .know-container .know-card img:first-child {
    display: inline-block;
}

.bnpl-project .where-container .know-container .know-card img:nth-of-type(2) {
    display: none;
}

.bnpl-project .where-container .know-container .know-card:hover img:first-child {
    display: none;
}

.bnpl-project .where-container .know-container .know-card:hover img:nth-of-type(2) {
    display: inline-block;
}

.bnpl-project .where-container .know-container .know-card h6 {
    text-align: center;
    margin-bottom: 20px;
}

.bnpl-project .where-container .know-container .know-card:hover h6 {
    color: #fff;
}

.bnpl-project .where-container .know-container .know-card .btn {
    color: #4F0C84;
    border: 1px solid #C4C4C4;
    background-color: #fff;
    padding: 5px 30px;
    transition: color .5s ease-in-out;

}

.bnpl-project .where-container .know-container .know-card:hover .btn {
    color: #fff;
    background-color: transparent;

}

.bnpl-project .for-whom {
    background-image: url('./../../assets/BnplIcons/buyBG.jpg');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 500px;
}

.bnpl-project .for-whom {
    padding: 50px 130px;
}

.bnpl-project .for-whom h2 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff;
}

.bnpl-project .for-whom h6 {
    text-align: center;
    margin-bottom: 40px;
    color: #fff;
    font-weight: 400;
}

.bnpl-project .whom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bnpl-project .whom-container p {
    color: #4F0C84;
    font-weight: bold;
}

.bnpl-project .Eligibility {
    background-image: url("./../../assets/BnplIcons/eliBG2.png");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 500px;
    padding-left: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bnpl-project .Eligibility h2 {
    color: #fff;
    font-size: 58px;
    font-weight: bold;
    margin-bottom: 40px;
}

.bnpl-project .Eligibility ul li {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.bnpl-project .payment-container {
    padding: 50px 130px;
}

.bnpl-project .payment-container h2 {
    color: #1F1E1D;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
}

.bnpl-project .payment-container p {
    color: #1F1E1D;
    font-weight: 400;
}

.bnpl-project .payment-container .cards-container {
    display: flex;
    justify-content: space-between;
}

.bnpl-project .payment-container .cards-container img {
    width: 180px;
}


.bnpl-project .why-qr h2 {
    text-align: center;
}

.bnpl-project .why-qr h3 {
    text-align: center;
    margin-top: 20px;
}

.bnpl-project .why-qr p {
    text-align: center;
    color: #1F1E1D;
    font-size: 14px;
    margin-top: 40px;
    font-weight: 600;
}

.bnpl-project .why-qr .work-item-container .work-item img {
    margin-bottom: 20px;
    width: 64px;
    height: 64px;
}

.bnpl-project .payment-container .scan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bnpl-project .payment-container .scan-container h2 {
    font-size: 40px;
}

.bnpl-project .payment-container .scan-container p {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 800;
}

.bnpl-project .payment-container .scan-container img {
    width: 250px;
    margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
    .bnpl-project .bnpl-header {
        height: unset;
        padding-left: unset;
        padding: 54px 0;
    }
    .bnpl-project .bnpl-header h2,
    .bnpl-project .bnpl-header p {
        margin: 0;
        text-align: center;
    }
    .bnpl-project .bnpl-header h2 br,
    .bnpl-project .bnpl-header p br {
        display: none;
    }
    .bnpl-project .bnpl-header h2{
        width: 85%;
        margin: auto;
    }
    .bnpl-project .bnpl-header h2 span:first-child {
        font-size: 28px;
        font-family: 'Ubuntu-Medium';
    }
    .bnpl-project .bnpl-header h2 span:last-child {
        font-size: 26px;
    }
    .bnpl-project .bnpl-header h2 {
        font-size: 28px;
        margin-bottom: 28px;
    }
    .bnpl-project .bnpl-header p {
        margin-bottom: 40px;
        font-family: 'Ubuntu-Regular';
    }
    .download-app {
        text-align: center;
    }
    .bnpl-project .bnpl-header .btn {
        color: #4F0C84;
        font-family: 'Ubuntu-Bold';
    }
    .bnpl-project .how-work {
        padding: 0;
    }
    .bnpl-project .where-container h2 {
        font-family: 'Ubuntu-Medium';
        font-size: 32px;
        text-align: center;
        padding-top: 32px;
    }
    .bnpl-project .where-container p {
        font-family: 'Ubuntu-Regular';
        font-size: 14px;
        text-align: center;
        width: 85%;
        margin: auto;
        margin-bottom: 45px;
    }
    .bnpl-project .where-container p br {
        display: none;
    }
    .where-container .row,
    .where-container .row .col:last-child,
    .bnpl-project .where-container .know-container {
        display: block;
    }
    .bnpl-project .where-container .know-container .know-card,
    .bnpl-project .where-container .know-container .know-card:hover {
        margin: 32px auto;
        width: 245px;
        height: 280px;
    }
    .bnpl-project .where-container .know-container .know-card h6,
    .bnpl-project .where-container .know-container .know-card h6:hover {
        margin-top: 27px;
        font-size: 20px;
        font-family: 'Ubuntu-Medium';
    }
    .bnpl-project .where-container .know-container .know-card img:first-child,
    .bnpl-project .where-container .know-container .know-card img:first-child:hover {
        margin-top: 34px;
    }
    .bnpl-project .where-container .know-container .know-card .btn,
    .bnpl-project .where-container .know-container .know-card .btn:hover {
        width: 180px;
        height: 40px;
        font-family: 'Ubuntu-Medium';
    }
    .bnpl-project .where-container .know-container .know-card {
        border: 0;
    }
    .bnpl-project .for-whom {
        padding: 0;
    }
    .bnpl-project .for-whom {
        height: unset;
    }
    .bnpl-project .whom-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .bnpl-project .for-whom h6 br {
        display: none;
    }
    .bnpl-project .for-whom h6 {
        margin-bottom: 32px;
        font-family: 'Ubuntu-Regular';
        width: 95%;
        margin: 0 auto 32px auto;
    }
    .bnpl-project .for-whom h2 {
        font-family: 'Ubuntu-Medium';
        font-size: 32px;
        padding-top: 50px;
    }
    .bnpl-project .whom-container p {
        font-family: 'Ubuntu-Medium';
    }
    .bnpl-project .how-work .work-item-container {
        display: block;
    }
    .bnpl-project .why-qr p,
    .bnpl-project .why-qr h2,
    .bnpl-project .why-qr .work-item-container .work-item img,
    .bnpl-project .how-work .work-item-container .work-item h5,
    .bnpl-project .how-work .work-item-container .work-item h6 { 
        margin: 0;
    }
    .bnpl-project .why-qr h2 {
        font-size: 26px;
        font-family: 'Ubuntu-Medium';
        padding-top: 68px;
        margin-bottom: 21px;
    }
    .bnpl-project .why-qr p br {
        display: none;
    }
    .bnpl-project .why-qr p{
        font-family: 'Ubuntu-Regular';
    }
    .bnpl-project .how-work .work-item-container {
        margin-top: 61px;
    }
    .bnpl-project .why-qr .work-item-container .work-item img {
        width: 34px;
        height: 24px;
        margin-bottom: 18px;
    }
    .bnpl-project .how-work .work-item-container .work-item h5 {
        font-family: 'Ubuntu-Medium';
        margin-bottom: 4px;
    }
    .bnpl-project .how-work .work-item-container .work-item h6 {
        font-size: 16px;
        font-family: 'Ubuntu-Regular';
    }
    .bnpl-project .how-work .work-item-container .work-item {
        margin-bottom: 30px;
    }
    .bnpl-project .Eligibility {
        height: unset;
        padding: 0;
    }
    .bnpl-project .Eligibility h2 {
        margin: 0;
        font-family: 'Ubuntu-Medium';
        font-size: 24px;
        text-align: center;
        padding-top: 44px;
        margin-bottom: 21px;
    }
    .bnpl-project .Eligibility ul li {
        font-family: 'Ubuntu-Regular';
        font-size: 14px;
    }
    .Eligibility ul {
        margin-bottom: 36px;
    }
    .bnpl-project .why-qr .work-item-container .work-item img {
        width: 100px;
        height: 100px;
        margin-bottom: 0;
    }
    .how-work .work-item-container .row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .bnpl-project .why-qr h2 {
        padding-top: 48px;
        font-size: 24px;
    }
    .bnpl-project .how-work .work-item-container {
        margin-top: 4px;
    }
    .bnpl-project .why-qr h3 {
        font-family: 'Ubuntu-Medium';
        font-size: 28px;
        margin-top: 24px;
        margin-bottom: 40px;
    }
    .bnpl-project .payment-container {
        padding: 0;
    }
    .bnpl-project .payment-container h2 {
        margin: 0;
    }
    .bnpl-project .payment-container .scan-container h2 {
        font-size: 32px;
        font-family: 'Ubuntu-Medium';
    }
    .bnpl-project .payment-container .scan-container p {
        margin-top: 8px;
        font-family: 'Ubuntu-Regular';
    }
    .bnpl-project .payment-container .scan-container p {
        margin-bottom: 18px;
    }
    .bnpl-project .payment-container h2 {
        margin: 0;
        font-size: 32px;
        padding-top: 32px;
        margin-bottom: 12px;
    }
}