.mySwiper {
    min-height: 600px;
    width: 100%;
    background-image: url('./../../assets/vision.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mySwiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    color: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    flex-direction: column;
}

.mySwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mySwiper .swiper-button-prev {
    margin-left: 80px;
    color: #fff;
}

.mySwiper .swiper-button-next {
    margin-right: 80px;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .mySwiper {
        min-height: 300px;
    }
    .swiper-wrapper h1 {
        font-family: Ubuntu-Medium;
        font-size: 24px;
    }

    .swiper-wrapper h4 {
        width: 64%;
        font-family: Roboto-Medium;
        font-size: 14px;
    }

    .swiper-wrapper h4 br {
        display: none;
    }
}