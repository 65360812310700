.navbar {
    background-color: #fff !important;
}

#proj-nav .nav-container {
    padding: 0 110px;
}

#proj-nav .nav-container .logo-container {
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

#proj-nav .nav-container .logo-container p {
    color: #1F1E1D;
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
}

#proj-nav .lang-btn {
    border: 1px solid #D2D4DB !important;
    background-color: #fff !important;
    color: #03151F !important;
    font-weight: 600;
    margin-right: 20px;
}

@media only screen and (max-width: 768px) {
    #proj-nav {
        display: none;
    }
}