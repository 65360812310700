/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
	.mobile-side-menu {
		width: 80%;
		margin: 18px auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.mobile-side-menu .menu-items .menu {
		position: fixed;
		width: 115%;
		background-image: url('../../assets/mobile-menu-bk.png');
		background-repeat: no-repeat;
		background-size: cover;
		height: 104%;
		z-index: 10002;
		top: -23px;
		right: 0;
		left: -21px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.mobile-side-menu .menu-items .menu ul {
		padding: 0;
		list-style: none;
	}
	.mobile-side-menu .menu-items .menu ul li {
		margin-bottom: 22px;
	}
	.mobile-side-menu .menu-items .menu ul li a,
	.mobile-side-menu .menu-items .menu ul li #navbarScrollingDropdown {
		color: #e0f3ff;
		font-size: 18px;
		text-decoration: unset;
	}
	.mobile-side-menu .menu-items .menu .close-btn {
		position: absolute;
		top: 55px;
		right: 65px;
		background-color: unset;
		border: 0;
	}
	.mobile-side-menu .menu-items .menu .lang-btn {
		position: absolute;
		bottom: 120px;
		width: 130px;
		height: 45px;
		font-size: 12px;
		font-family: Ubuntu-Regular !important;
	}
	.mobile-side-menu .menu-items .burger-menu button {
		background-color: unset;
		border: 0;
	}
	.web-menu {
		display: none;
    }
    #navbarScrollingDropdown {
        color: #e0f3ff !important;
	}
	.dropdown-menu {
		left: -45px !important;
		top: 19px !important;
		background-color: #EAE9F5 !important;
		width: 300px;
	}
	.dropdown-menu .dropdown-item * {
		color: #000 !important;
		display: flex;
		align-items: center;
		font-family: 'Ubuntu-Bold';
		margin-bottom: 0;
	}
	.dropdown-menu .dropdown-item .menu-item img {
		margin-right: 12px;
	}
	.slice-top {
		display: none !important
	}
}

@media only screen and (min-width: 820px) { 
	.mobile-side-menu {
		width: 80%;
		margin: 18px auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.mobile-side-menu .menu-items .menu {
		position: fixed;
		width: 115%;
		background-image: url('../../assets/mobile-menu-bk.png');
		background-repeat: no-repeat;
		background-size: cover;
		height: 105%;
		z-index: 10002;
		top: -23px;
		right: 0;
		left: -32px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.mobile-side-menu .menu-items .menu ul {
		padding: 0;
		list-style: none;
	}
	.mobile-side-menu .menu-items .menu ul li {
		margin-bottom: 22px;
	}
	.mobile-side-menu .menu-items .menu ul li a,
	.mobile-side-menu .menu-items .menu ul li #navbarScrollingDropdown {
		color: #e0f3ff !important;
		font-size: 18px;
		text-decoration: unset;
	}
	.mobile-side-menu .menu-items .menu .close-btn {
		position: absolute;
		top: 55px;
		right: 65px;
		background-color: unset;
		border: 0;
	}
	.mobile-side-menu .menu-items .menu .lang-btn {
		position: absolute;
		bottom: 120px;
		width: 130px;
		height: 45px;
		font-size: 12px;
		font-family: Ubuntu-Regular !important;
	}
	.mobile-side-menu .menu-items .burger-menu button {
		background-color: unset;
		border: 0;
	}
    #navbarScrollingDropdown {
        color: #000 !important;
	}
	.dropdown-menu {
		left: -20px !important;
		top: 49px !important;
		background-color: #EAE9F5 !important;
		width: 350px;
	}
	.dropdown-menu .dropdown-item * {
		color: #000 !important;
		display: flex;
		align-items: center;
	}
	.dropdown-menu .dropdown-item .menu-item img {
		margin-right: 12px;
	}
	.slice-top {
		display: none;
	}
}