.featuers-container {
    padding: 50px 130px;
    background-image: url('./../../assets/qrIcons/easyBg.png');
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 500px;
    margin-bottom: 20px;
}

.finpay.featuers-container {
    background-image: url('./../../assets/finpayIcons/easyFinpay.jpg');
}

.featuers-container .featuers-left {
    text-align: right;
}


.featuers-container img {
    width: 300px;
    height: 450px;
    margin-bottom: -50px;
}

.featuers-container h3 {
    color: #fff;
}

.featuers-container p {
    color: #fff;
    margin-bottom: 40px;
    font-size: 14px;
}